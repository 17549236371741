'use client';

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons/faLockKeyhole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@login/components/Button';
import { FormInput } from '@login/components/forms/FormInput';
import { NotificationWrapper } from '@login/components/Notification';
import { useNotificationContext } from '@login/context/UseNotificationContext';
import { useLoginAttemptTracker } from '@login/hooks/useLoginAttemptTracker';
import { useProgressRouter } from '@login/hooks/useProgressRouter';
import { LoginType } from '@login/pages/api/auth/login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLogin, useLoginWithGoogle } from 'apiClient/auth';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import GoogleLogin from '../components/GoogleLogin';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email is a required field')
    .email('Please enter a valid email'),
  password: Yup.string().required('Password is a required field'),
});

export type Schema = Yup.Asserts<typeof schema>;

export default function Page() {
  const searchParams = useSearchParams();
  const returnUrl = searchParams?.get('returnUrl') || '';
  const router = useProgressRouter();
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { showNotification } = useNotificationContext();

  const { handleSubmit, control } = useForm<Schema>({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const login = useLogin();
  const loginWithGoogle = useLoginWithGoogle();

  const { isBlocked, incrementFailedAttempts, resetAttempts } =
    useLoginAttemptTracker(10, 15 * 60 * 1000);

  const signInUser = async (data: LoginType) => {
    if (isBlocked) {
      showNotification({
        message: 'Too many failed attempts. Please try again later.',
        type: 'error',
      });
      return;
    }

    setIsLoading(true);
    const result = await login(data);

    if (result && result.ok) {
      resetAttempts(); // Reset failed attempts on successful login
      if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        router.push(`/productPicker`);
      }
    } else {
      incrementFailedAttempts(); // Increment failed attempts on unsuccessful login
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      showNotification({
        message: result?.message as string,
        type: 'error',
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const signUpUser = handleSubmit((data) =>
    signInUser({ ...data, recaptchaToken }),
  );

  const handleGoogleSignin = async ({ idToken }: { idToken: string }) => {
    if (idToken) {
      const result = await loginWithGoogle({ idToken, recaptchaToken });
      if (result && result.ok) {
        if (returnUrl) {
          window.location.href = returnUrl;
        } else {
          router.push(`/productPicker`);
        }
      } else {
        window.grecaptcha.reset();
        showNotification({
          message: result?.message || 'Login failed',
          type: 'error',
        });
      }
    }
  };

  return (
    <GoogleOAuthProvider
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
    >
      <Script src="https://apis.google.com/js/platform.js" />
      <div className="flex flex-col justify-center items-center py-2 sm:py-6 lg:px-8 bg-ice-grey-1 min-h-screen">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center mb-2 sm:mb-6">
          <Image
            src="/unlayer-logo.svg"
            alt="Unlayer logo"
            quality={100}
            width={140}
            height={50}
          />
          <h2 className="mt-6 text-center text-xl sm:text-2xl tracking-tight text-ice-grey-10">
            Sign In
          </h2>
          <p className="text-sm sm:text-base text-ice-grey-8">
            Enter your credentials to login to your account.
          </p>
        </div>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md lg:max-w-md xl:max-w-lg 2xl:max-w-xl bg-white rounded-md sm:min-h-0">
          <div className="py-4 sm:py-10 px-4 shadow sm:rounded-lg sm:px-10">
            <NotificationWrapper allowedNotificationTypes={['error']} />
            <form noValidate onSubmit={signUpUser}>
              <div className="mb-3 mt-2">
                <FormInput
                  id="login-email"
                  placeholder="amelia@acme.com"
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  label="Email"
                  control={control}
                  name="email"
                />
              </div>
              <FormInput
                id="login-password"
                placeholder="Enter your password"
                icon={<FontAwesomeIcon icon={faLockKeyhole} />}
                iconPosition="both"
                secondIcon={
                  !isPasswordVisible ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="cursor-pointer"
                      onClick={() => setIsPasswordVisible(true)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      className="cursor-pointer"
                      onClick={() => setIsPasswordVisible(false)}
                    />
                  )
                }
                label="Password"
                control={control}
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
              />
              <div className="flex items-center justify-end mt-2 mb-4">
                <div className="text-sm">
                  <Link
                    href="/forgot-password"
                    className="font-medium text-primary-blue"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              {process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
                <div className="flex mb-4">
                  <ReCAPTCHA
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                    onChange={(value) => {
                      setRecaptchaToken(value as string);
                    }}
                    theme="light"
                  />
                </div>
              )}
              <Button
                type="submit"
                isLoading={isLoading}
                className="w-full"
                id="login-button"
              >
                Sign in
              </Button>
            </form>

            <div className="items-center justify-center flex mt-4">
              <div>
                <div className="text-sm">
                  {"Don't have an account?"}{' '}
                  <Link
                    href={`/signup${
                      returnUrl ? `?returnUrl=${returnUrl}` : ''
                    }`}
                    className="font-medium text-primary-blue"
                  >
                    <button className="text-primary-blue" id="register-button">
                      Register
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="relative mt-4">
            <div className="absolute inset-0 flex items-center mx-2 sm:mx-8">
              <div className="w-full border-t border-regular-grey-4" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-ice-grey-1 px-2 sm:px-3 text-ice-grey-7">
                Or
              </span>
            </div>
          </div>

          <GoogleLogin
            onSuccess={handleGoogleSignin}
            onFailure={() => {
              showNotification({
                message: 'Login failed. Please check your email and password.',
                type: 'error',
              });
            }}
            type="login"
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export const dynamic = 'force-dynamic';
