import { useEffect, useState } from 'react';

// Custom hook to track login attempts
export function useLoginAttemptTracker(
  maxAttempts = 10,
  timeWindowMs = 15 * 60 * 1000,
) {
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    const storedAttempts = localStorage.getItem('failedAttempts');
    const storedTimestamp = localStorage.getItem('lastAttemptTime');

    const attempts = storedAttempts ? parseInt(storedAttempts, 10) : 0;
    const lastAttemptTime = storedTimestamp ? parseInt(storedTimestamp, 10) : 0;

    const currentTime = Date.now();
    const timeSinceLastAttempt = currentTime - lastAttemptTime;

    if (timeSinceLastAttempt > timeWindowMs) {
      // Reset attempts if the time window has passed
      setFailedAttempts(0);
      localStorage.removeItem('failedAttempts');
      localStorage.removeItem('lastAttemptTime');
    } else {
      setFailedAttempts(attempts);
    }

    // Block user if attempts exceed max attempts
    if (attempts >= maxAttempts) {
      setIsBlocked(true);
    }
  }, [maxAttempts, timeWindowMs]);

  const incrementFailedAttempts = () => {
    const newFailedAttempts = failedAttempts + 1;
    const currentTime = Date.now();

    setFailedAttempts(newFailedAttempts);
    localStorage.setItem('failedAttempts', newFailedAttempts.toString());
    localStorage.setItem('lastAttemptTime', currentTime.toString());

    if (newFailedAttempts >= maxAttempts) {
      setIsBlocked(true);
    }
  };

  const resetAttempts = () => {
    setFailedAttempts(0);
    localStorage.removeItem('failedAttempts');
    localStorage.removeItem('lastAttemptTime');
    setIsBlocked(false);
  };

  return { failedAttempts, isBlocked, incrementFailedAttempts, resetAttempts };
}
